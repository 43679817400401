import React from "react"
import "../wdyr"
import GeneratePassword from "../components/user/generatePassword"
import SEO from "../components/seo"

const GeneratePasswordPage = props => {
  return (
    <>
      <SEO title="GeneratePassword" />
      <GeneratePassword />
   </>
  )
}

export default GeneratePasswordPage
